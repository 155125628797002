import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "../components/seo"

import "../styles/faq.css"

const FaqEntry = ({ question, answer }) => (
  <>
    <dt>{question}</dt>
    <dd dangerouslySetInnerHTML={{ __html: answer }}></dd>
  </>
)

const Faq = props => {
  const data = useStaticQuery(
    graphql`
      {
        allDirectusServicepage {
          nodes {
            faq {
              answer
              question
            }
            title
          }
        }
      }
    `
  )

  const { nodes } = data.allDirectusServicepage

  return (
    <>
      <SEO title="FAQ" />

      <div className="container faq">
        {nodes.map((service, index) => {
          if (!service.faq) return null
          return (
            <React.Fragment key={index}>
              <h1>{service.title} FAQ</h1>
              {service.faq.map(({ question, answer }, index) => (
                <FaqEntry key={index} question={question} answer={answer} />
              ))}
            </React.Fragment>
          )
        })}
      </div>
    </>
  )
}

export default Faq
